import PhoneInput from 'react-phone-number-input';
import React, { Component } from 'react';
import { navigate } from 'gatsby-link';

import AccountFrame from '../../components/account/AccountFrame';
import withAccount from '../../containers/plugins/withAccount';
import { getCmsValue } from '../../utils/utils';

import 'react-phone-number-input/style.css';

class Register extends Component {
  constructor(props) {
    super(props);

    this.initialState = {
      acceptsMarketing: false,
      email: '',
      firstName: '',
      lastName: '',
      password: '',
      passwordConfirm: '',
      phone: '',
      loading: false,
      error: null,
    };

    this.state = {
      ...this.initialState,
    };

    this.onPhoneChange = this.onPhoneChange.bind(this);
    this.onChange = this.onChange.bind(this);
    this.register = this.register.bind(this);
  }

  async register(e) {
    e.preventDefault();

    const { createAccount } = this.props;

    const { password, passwordConfirm } = this.state;

    if (password !== passwordConfirm) {
      this.setState((prevState) => ({
        ...prevState,
        error: 'Passwords do not match',
      }));
      return;
    }

    this.setState((prevState) => ({
      ...prevState,
      loading: true,
    }));

    const response = await createAccount(this.state);

    this.setState((prevState) => ({
      ...prevState,
      loading: false,
    }));

    if (response.customerUserErrors.length === 0) {
      navigate(`/${this.props.site.siteUID}/account`);
    } else {
      this.setState((prevState) => ({
        ...prevState,
        error: response.customerUserErrors[0].message,
      }));
    }
  }

  onPhoneChange(number) {
    if (!number) return;
    this.onChange({
      target: {
        name: 'phone',
        value: number,
      },
    });
  }

  onChange(e) {
    if (!e) return;

    this.setState((prevState) => ({
      ...prevState,
      [e.target.name]: e.target.value,
    }));
  }

  render() {
    const { site } = this.props;
    const { email, firstName, lastName, password, passwordConfirm, phone } =
      this.state;

    return (
      <AccountFrame
        title={getCmsValue(site.strings.accountPage__register__title, site)}
        {...this.props}
      >
        <div className="register">
          <div className="account-section account-section__information">
            <div className="account-section__title">
              <h3>
                {getCmsValue(site.strings.accountPage__register__title, site)}
              </h3>
            </div>

            <form className="account-section__form" onSubmit={this.register}>
              <div className="form__input-wrapper">
                <label htmlFor="email">
                  {getCmsValue(site.strings.accountPage__register__email, site)}
                  <input
                    name="email"
                    type="email"
                    value={email}
                    onChange={this.onChange}
                    required
                  />
                </label>

                <label htmlFor="password">
                  {getCmsValue(
                    site.strings.accountPage__register__password,
                    site
                  )}
                  <input
                    name="password"
                    type="password"
                    value={password}
                    onChange={this.onChange}
                  />
                </label>

                <label htmlFor="passwordConfirm">
                  {getCmsValue(
                    site.strings.accountPage__register__passwordConfirm,
                    site
                  )}
                  <input
                    name="passwordConfirm"
                    type="password"
                    value={passwordConfirm}
                    onChange={this.onChange}
                  />
                </label>
                <span className="error">{this.state.error}</span>
              </div>

              <div className="form__submit-wrapper">
                <button type="submit" disabled={this.state.loading}>
                  {getCmsValue(
                    site.strings.accountPage__register__signUp,
                    site
                  )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </AccountFrame>
    );
  }
}

export default withAccount(Register);
